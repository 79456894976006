<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">For each of the 3 molecules below, match the name to the structure:</p>
      <v-simple-table>
        <tr>
          <td>a)</td>
          <td>
            <v-img :src="imageAName" style="max-width: 128px" />
          </td>
          <td>
            <v-select v-model="inputs.input1" :items="optionsShuffled" label="Select Option" />
          </td>
        </tr>
        <tr>
          <td>b)</td>
          <td>
            <v-img :src="imageBName" style="max-width: 137px" />
          </td>
          <td>
            <v-select v-model="inputs.input2" :items="optionsShuffled" label="Select Option" />
          </td>
        </tr>
        <tr>
          <td>c)</td>
          <td>
            <v-img :src="imageCName" style="max-width: 85px" />
          </td>
          <td>
            <v-select v-model="inputs.input3" :items="optionsShuffled" label="Select Option" />
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA1Q3',
  components: {
    StembleLatex,
    seededShuffle,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options: [
        {text: '2-chlorobenzoic acid', value: '2'},
        {text: '3-chlorobenzoic acid', value: '3'},
        {text: '4-chlorobenzoic acid', value: '4'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    imageAName() {
      return '/img/assignments/uci-51lb-a1-q3-option1.png';
    },
    imageBName() {
      return '/img/assignments/uci-51lb-a1-q3-option2.png';
    },
    imageCName() {
      return '/img/assignments/uci-51lb-a1-q3-option3.png';
    },
  },
};
</script>
